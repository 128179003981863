<template>
	<div class="app-container body-tabs-shadow">
		<div class="app-container">
			<div class="row no-gutters md:h-screen">
				<div class="col-md-6" :class="{'theme-side': systemMode === 'main'}"
					 :style="GET_COMPANY_SUBSCRIPTION_SETTING.menu_color ? 'background:' +GET_COMPANY_SUBSCRIPTION_SETTING.menu_color : 'background:#181C4C'">
					<div class="p-4 flex flex-col space-y-2">
						<div class="mb-5" v-if="systemMode === 'whitelabel'">
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="img-fluid md:h-12 logo-top md:pl-20 h-12"
									 :src="GET_COMPANY_SUBSCRIPTION_SETTING.full_logo" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ GET_COMPANY_SUBSCRIPTION_SETTING.seller_login_description }}
							</div>
						</div>
						<div class="mb-5" v-else>
							<div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<!-- <div> -->
								<img class="img-fluid md:h-auto logo-top md:pl-20 h-12"
									 src="./../../assets/images/logo/logo.png" alt="logo">
								<!-- </div> -->
								<!-- <div class="font-semibold text-lg md:text-2xl text-white md:tracking-wide whitespac-nowrap ">B2B Portal</div> -->
							</div>
							<div class="flex theme-header-size md:text-xl md:ml-10 text-white mt-4 login-description">
								{{ $t('welcomeEntry') }}
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="p-4 flex flex-col space-y-2">
						<div class="mb-5">
							<div class="flex md:justify-center align-items-center w-full space-x-4 md:-ml-10 mt-5">
								<div class="font-semibold text-lg theme-header-size text-blue-900 md:tracking-wide">
									{{ $t('setUpAccount') }}
								</div>
							</div>
						</div>
						<div class="flex md:justify-end md:mt-16">
							<div class="login-border-section w-full md:w-4/5 md:pl-10 md:pr-10">
								<form class="mt-1 mb-2" @submit.prevent="registerCustomer">
									<!-- <div class="form-group">
										<label for="exampleEmail" class="override-pb override-pt col-form-label label-context -mt-3"></label>
										<div>
											<div class="font-semibold text-2xl link-color whitespace-nowrap">{{ $t('loginHeader') }}</div><br>
										</div>
									</div> -->
									<div class="form-group">
										<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
												$t('inviteCode')
											}}</label>
										<div>
											<input v-model="code" disabled name="code" type="text"
												   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												   required>
										</div>
									</div>

									<!-- <div class="form-group">
										<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{ $t('username') }}</label>
										<div>
											<input v-model="username" name="username" type="text" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full" required>
										</div>
									</div> -->
									<div class="form-group">
										<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
												$t('firstName')
											}}</label>
										<div>
											<input v-model="firstName" name="username" type="text"
												   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												   required>
										</div>
									</div>
									<div class="form-group">
										<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
												$t('lastName')
											}}</label>
										<div>
											<input v-model="lastName" name="username" type="text"
												   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												   required>
										</div>
									</div>
									<div class="form-group">
										<label for="exampleEmail"
											   class="text-base font-semibold text-blue-900">{{ $t('email') }}</label>
										<div>
											<input v-model="email" name="email" type="email"
												   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												   required>
										</div>
									</div>
									<div class="form-group">
										<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
												$t('password')
											}}</label>
										<div>
											<input v-model="password" name="password" type="password"
												   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												   required>
										</div>
									</div>
									<div class="form-group">
										<label for="exampleEmail" class="text-base font-semibold text-blue-900">{{
												$t('confirmPassword')
											}}</label>
										<div>
											<input v-model="confirmPassword" name="password" type="password"
												   class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full"
												   required>
										</div>
									</div>
									<div class="form-group">
										<label for="exampleEmail"></label>
										<div>
											<button class="w-full login-btn-success px-4 py-2 rounded-md" type="submit"
													:style="GET_COMPANY_SUBSCRIPTION_SETTING.button_color ? 'background:' +GET_COMPANY_SUBSCRIPTION_SETTING.button_color : 'background:#E8B105'"
													:disabled="processing">
												<span class="text-white" v-if="processing">{{
														$t('creatingAccount')
													}}</span>
												<span class="text-white" v-else>{{ $t('setUpAccount') }}</span>
											</button>
										</div>
									</div>
									<!-- <div class="form-group mt-1">
										<label for="exampleEmail" class="override-pb override-pt col-form-label label-context"></label>
										<div>
											<span class="font-md-login">{{ $t('forgetPass') }}? <router-link :to="{name : 'forget'}" class="no-deco no-de-hover link-color">{{ $t('reset') }}</router-link></span><br><br>
											<span class="font-md-login">{{ $t('notCus') }}? <router-link :to="{name : 'register'}" class="no-deco no-de-hover link-color">{{ $t('welcome') }}</router-link></span>
										</div>
									</div> -->
									<div class="form-group mt-10">
										<!-- <div>
											<span class="font-md-login text-base font-semibold text-blue-900 text-gray-900">{{ $t('golf') }}
												<a target="_blank" href="https://golf.automatiseramera.se/login/" class="font-semibold text-base font-semibold text-blue-900 hover:no-underline text-blue-700">
													{{ $t('login') }}
												</a>
											</span><br><br>
										</div> -->
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="container text-center md:bottom-0 md:absolute mb-4 flex justify-center">
						<span class="text-blue-900">Copyright © <span v-if="systemMode === 'whitelabel'">{{ current }} {{
								GET_COMPANY_SUBSCRIPTION_SETTING.brand_name
							}}</span><span
							v-else>{{ current }} B2B Portal</span></span>
						<span>
							<locale-component :show-text="true" :height="5" :width="5"/>
                        </span>
					</div>
				</div>
			</div>
		</div>
		<Notification/>
	</div>
</template>

<script>
// import Vue from 'vue'
import {mapGetters} from 'vuex'
import i18n from '@/plugins/i18n'
import Notification from './../../customComponent/NotificationAlert'
import LocaleComponent from "../../components/LocaleComponent";

export default {
	name: 'login',
	components: {
		Notification, LocaleComponent
	},
	data() {
		return {
			current: new Date().getFullYear(),
			loaded: false,
			firstName: '',
			lastName: '',
			systemMode: '',
			code: '',
			username: '',
			email: '',
			password: '',
			confirmPassword: '',
			domain: '',
			processing: false,
		}
	},
	computed: {
		...mapGetters({
			GET_LOCALE_LANG: 'utility/GET_LOCALE_LANG',
			GET_COMPANY_SUBSCRIPTION_SETTING: 'portal/GET_COMPANY_SUBSCRIPTION_SETTING'
		})
	},
	mounted() {
		const {code, domain} = this.$route.query
		if (code) {
			this.code = code
			this.domain = domain
		}
	},
	beforeMount() {
		this.systemMode = process.env.VUE_APP_SYSTEM_MODE
	},
	methods: {
		registerCustomer() {
			if (this.code === '' || this.password === '' || this.email === '' || this.firstName === '' || this.lastName === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.code.length > 50 || this.password.length > 50 || this.email.length > 50 || this.firstName.length > 50 ||
				this.lastName.length > 50) {
				this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
			} else if (!this.$services.helpers.validateEmailAddress(this.email)) {
				this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
			} else if (this.password.length < 6) {
				this.$services.helpers.notification(this.$t('passwordGreater'), 'error', this)
			} else if (this.password !== this.confirmPassword) {
				this.$services.helpers.notification(this.$t('passwordNotMatch'), 'error', this)
			} else {
				this.processing = true
				const payload = {
					invite_code: this.code,
					// username : this.username,
					email: this.email,
					first_name: this.firstName,
					last_name: this.lastName,
					password: this.password,
				}
				this.$store.dispatch('auth/acceptInvitation', payload)
					.then(_ => {
						this.processing = false
						this.$services.helpers.notification(this.$t('accountCreated'), 'success', this)
						setTimeout(() => {
							this.$router.push({name: 'login'})
						}, 2000)
					}).catch((err) => {
					this.processing = false
					if (err.status === 400) {
						this.$services.helpers.notification(err.data.message, 'error', this)
					}
				})
			}
		},
		changeLocale(locale) {
			i18n.locale = locale
			this.$store.commit('utility/CHANGE_LOCALE', locale)
		},
		setRandomColor() {
			const color = ['#512DA8', '#7E3469', '#EE4D81', '#344258', '#546295', '#1E2C41']
			const choose = color[Math.floor(Math.random() * color.length)]
			if (!window.localStorage.getItem('randomc')) {
				window.localStorage.setItem('randomc', choose)
			}
			return false
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

#app {
	overflow-y: hidden !important;
}

.login-head-text {
	font-size: 1.2rem !important;
}

.login-head-text-xl {
	font-size: 1.5rem !important;
}

.override-form-field {
	border: 0;
	border-bottom: 1px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: unset !important;
	border-radius: unset !important;
}

body {
	background: #fff !important;
}

.override-pt {
	padding-top: 1.2rem !important;
}

.override-pb {
	padding-bottom: unset !important;
}

.custom-rem {
	padding: 5rem;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control {
	// border: 1px solid $form-border !important;
}

.form-control:focus {
	box-shadow: unset !important;
	// border: 1px solid $theme-primary-color !important;
}

.theme-side {
	background: $production-theme;
}

.login-btn-success {
	background: #E8B105;
}

.logo {
	height: 95px;
}

.login-description {
	position: relative;
	top: 177px;
}

@media only screen and (max-width: 600px) {
	.logo {
		height: 48px;
	}
	.login-description {
		top: 0px
	}
}
</style>
